<template>
  <div class="courts-list fixed-draggable-dynamic-table-wrapper-height semi-box-border-y">

    <draggable-dynamic-table ref="saleDetailReportTable"
                             :columns="columnsLabel"
                             :options="options"
                             :data="data"
                             :showFilterBtn="false"
                             :filters="filtersList"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @row:clicked="handleSelectReport"
                             @load:more="getSaleDetailReport()"/>


    <!-- show user invoices prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showUserInvoicesPromptStatus"
      @close="showUserInvoicesPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <!--            <div @click="editUserPromptStatus = true">
                          <custom-icon icon="EDIT" color="warning"/>
                        </div>-->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('report.saleDetail.invoices.dynamicTitle', {name: `${selectedReport.gender === 1 ? this.$t('genderTypes.dynamicMan') : this.$t('genderTypes.woman')} ${selectedReport.name}`}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showUserInvoicesPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <user-invoices-list :user-id="selectedReport.userId" :date-range="$store.state.helper.calendarDate"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <button id="printTableSaleDetail" v-show="false" @click="handlePrintSaleDetail"></button>
  </div>
</template>

<script>
  import axios from 'axios'
  import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
  import {
    addComma,
    checkUserPermissions,
    getAvatarUrl,
    getTimeFromServer,
    hideLoading,
    showLoading
  } from '../../../../assets/js/functions'
  import CustomIcon from '../../../../components/customIcon/customIcon'
  import {getTerms} from "../../../../http/requests/terms";
  import {getSaleDetailReport, printSaleDetailReport} from "../../../../http/requests/reports";
  import UserInvoicesList from "./userInvoices";
  import SelectTimeRange from "./selectTimeRange";
  import {getCourts} from "../../../../http/requests/courts";
  import {getTimes} from "../../../../http/requests/times";

  export default {
    name: 'saleDetailReport',
    metaInfo() {
      return {
        title: this.$t('report.saleDetail.title')
      }
    },
    components: {
      SelectTimeRange,
      UserInvoicesList,
      CustomIcon,
      DraggableDynamicTable
    },
    data() {
      return {
        requestSent: false,
        showUserInvoicesPromptStatus: false,
        loadingTimer: 0,
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'totalPrice',
            i18n: 'report.saleDetail.table.header.totalPrice',
            width: 'calc(100% / 6)',
            minWidth: 150,
            sortable: true,
            // locked: true,
            filter: true,
            filterTypes: [
              {
                icon: 'equals',
                name: 'برابر باشد',
                i18n: 'draggableTable.filter.types.equals',
                id: 1
              },
              {
                icon: 'not-equal',
                name: 'برابر نباشد',
                i18n: 'draggableTable.filter.types.notEqual',
                id: 2
              },
              {
                icon: 'less-than-equal',
                name: 'کوچکتر مساوی',
                i18n: 'draggableTable.filter.types.lessThanEquals',
                id: 3
              },
              {
                icon: 'greater-than-equal',
                name: 'بزرگتر مساوی',
                i18n:
                  'draggableTable.filter.types.greaterThanEquals',
                id: 4
              }
            ],
            footer: {},
          },
          {
            field: 'name',
            i18n: 'report.saleDetail.table.header.name',
            width: 'calc(100% / 6 * 2)',
            minWidth: 200,
            sortable: true,
            // locked: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'row',
            i18n: 'report.saleDetail.table.header.row',
            width: '70px',
            minWidth: 70,
            align: 'center',
            // locked: true,
            // sortable: true,
            footer: {
              /*type: 'auto-counter'*/
            }
          }
        ],
        data: [],
        filters: [],
        selectedTerm: this.$t('report.saleDetail.labels.all'),
        sorts: ['order[0]=final_price,desc'],
        selectedReport: {},
        filtersList: [],
        page: 1,
        endedList: false,
        getFiltersStatus: true,
        activeFiltersCount: 0,
        actions: [
          {
            toolbar: [
              {
                type: 'date',
                range: true,
                // permission: 'court.create'
              }
            ],
            leftToolbar: [
              // {
              //   id: 'printTableSaleDetail',
              //   // i18n: 'draggableDynamicTable.actions.print',
              //   icon: 'icon-printer',
              //   iconPack: 'feather'
              // },
              {
                id: 'toggleActiveFilter',
                icon: 'FILTER',
                // height: '27px',
                // width: '27px',
                iconPack: 'useral'
              }
            ]
          }
        ]
      }
    },
    created() {

      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      }, 50)

      this.getActiveFilters()
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getSaleDetailReport() {
        if (!this.requestSent) {
          this.requestSent = true

          clearTimeout(this.loadingTimer)
          this.loadingTimer = setTimeout(() => {
            if (!this.endedList) {
              if (this.$refs.saleDetailReportTable && this.data.length === 0) {
                this.$refs.saleDetailReportTable.loadMoreStatus = 'FirstLoad'
              } else if (this.$refs.saleDetailReportTable && this.data.length > 0) {
                this.$refs.saleDetailReportTable.loadMoreStatus = 'Loading'
              }

              getSaleDetailReport(this.page, this.filters, this.sorts).then((res) => {
                this.endedList = res.data.data.length === 0
                const reports = res.data.data
                reports.forEach((report) => {

                  this.data.push({
                    row: this.data.length + 1,
                    id: report.id,
                    name: `${report.user.name || ''} ${report.user.family || ''}`,
                    userId: report.user.id,
                    gender: report.user.gender,
                    term: this.selectedTerm,
                    addonPrice: {
                      type: 'price',
                      value: report.addons_price || '0'
                    },
                    timePrice: {
                      type: 'price',
                      value: report.time_price || '0'
                    },
                    totalPrice: {
                      type: 'price',
                      value: report.final_price || '0'
                    },
                  })
                })

                if (this.data.length === 0) {
                  this.$vs.notify({
                    title: this.$t('alert.message.title'),
                    text: this.$t('report.saleDetail.notifications.noData'),
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 5000,
                    color: 'warning'
                  })
                  this.handleClick('selectRangeDate')
                }

                this.page = res.data.pagination.current_page + 1
                if (res.data.pagination.current_page === 1) {
                  const row_index = this.columnsLabel.map((e) => {
                    return e.field
                  }).indexOf('row')
                  this.columnsLabel[row_index].footer.value = res.data.pagination.total
                }

                const price_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('totalPrice')
                if (price_index > -1) {
                  this.columnsLabel[price_index].footer = {
                    value: addComma(res.data.extra.total_price, true)
                  }
                }

                if (this.$refs.saleDetailReportTable) this.$refs.saleDetailReportTable.loadMoreStatus = ''

                this.requestSent = false
              })
                .catch((error) => {
                  if (this.$refs.saleDetailReportTable) this.$refs.saleDetailReportTable.loadMoreStatus = 'Danger'

                  this.requestSent = false
                })
            } else {

              this.requestSent = false
            }
          }, 400)
        }
      },
      setFilters(filters) {
        if (!this.getFiltersStatus) {
          const filters_list = []
          Object.keys(filters).forEach((key) => {
            switch (key) {

              case 'name':
                if (filters[key].search !== '') filters_list.push(`user=${filters[key].search}`)
                break

              case 'term':
                let termActives = []
                filters[key].search.forEach((item) => {
                  if (item.show) {
                    termActives.push(item.value)
                  }
                })
                if (termActives.length > 0) filters_list.push(`reserved_term=${termActives.join('_')}`)
                this.selectedTerm = filters[key].search.label
                break

              case 'time':
                let timeActives = []
                filters[key].search.forEach((item) => {
                  if (item.show) {
                    timeActives.push(item.value)
                  }
                })
                if (timeActives.length > 0) filters_list.push(`reserved_time=${timeActives.join('_')}`)
                break

              case 'court':
                let courtActives = []
                filters[key].search.forEach((item) => {
                  if (item.show) {
                    courtActives.push(item.value)
                  }
                })
                if (courtActives.length > 0) filters_list.push(`reserved_court=${courtActives.join('_')}`)
                break

              case 'timePrice':
                if (filters[key].search !== '') filters_list.push(`reserved_time_price=${filters[key].search},${filters[key].type.id}`)
                break

              case 'addonPrice':
                if (filters[key].search !== '') filters_list.push(`reserved_addons_price=${filters[key].search},${filters[key].type.id}`)
                break

              case 'totalPrice':
                if (filters[key].search !== '') filters_list.push(`reserved_final_price=${filters[key].search},${filters[key].type.id}`)
                break
            }
          })


          const date = this.$store.state.helper.calendarDate
          if (typeof date === 'object' && date.length > 1) {
            filters_list.push(`reserved_date=${date.join('_')}`)
          } else {
            filters_list.push(`reserved_date=${date}`)
          }

          this.data = []
          this.page = 1
          this.endedList = false
          this.filters = filters_list
          this.getSaleDetailReport()
        }
      },
      setSort(columns) {
        const sorts_list = []
        Object.keys(columns).forEach((key) => {
          switch (key) {

            case 'row':
              sorts_list.push(`order[0]=id,${columns[key] ? 'desc' : 'asc'}`)
              break

            case 'name':
              sorts_list.push(`order[0]=user,${columns[key] ? 'desc' : 'asc'}`)
              break

            case 'term':
              sorts_list.push(`order[0]=reserved_term,${columns[key] ? 'desc' : 'asc'}`)
              break

            case 'timePrice':
              sorts_list.push(`order[0]=reserved_time_price,${columns[key] ? 'desc' : 'asc'}`)
              break

            case 'addonPrice':
              sorts_list.push(`order[0]=reserved_addons_price,${columns[key] ? 'desc' : 'asc'}`)
              break

            case 'totalPrice':
              sorts_list.push(`order[0]=reserved_final_price,${columns[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

        this.data = []
        this.page = 1
        this.endedList = false
        this.sorts = sorts_list
        this.getSaleDetailReport()
      },

      getActiveFilters() {
        this.getTerms()
        this.getCourts()
        this.getTimes()
      },

      getTerms() {
        getTerms().then(response => {
          const terms = response.data.data
          let termList = []
          terms.forEach((term) => {
            termList.push({
              id: term.id,
              label: term.name,
              value: term.id,
              show: true
            })
          })
          this.filtersList.push({
            field: `term`,
            name: this.$t('report.saleDetail.filters.term'),
            type: 'checkbox',
            types: termList
          })
          this.activeFiltersCount += 1


          if (this.activeFiltersCount === 3) {
            this.getFiltersStatus = false
          }
        })
          .catch(() => {
            this.activeFiltersCount += 1
          })
      },

      getCourts() {
        getCourts().then((response) => {
          const courts = response.data.data

          let courtList = []
          courts.forEach((court) => {
            courtList.push({
              id: court.id,
              label: court.name,
              value: court.id,
              show: false
            })
          })

          this.filtersList.push({
            field: `court`,
            name: this.$t('report.saleDetail.filters.court'),
            type: 'checkbox',
            types: courtList
          })
          this.activeFiltersCount += 1


          if (this.activeFiltersCount === 3) {
            this.getFiltersStatus = false
          }
        })
          .catch(() => {
            this.activeFiltersCount += 1
          })
      },

      getTimes() {
        getTimes().then(response => {
          const times = response.data.data

          let timeList = []

          times.forEach((time) => {
            timeList.push({
              id: time.id,
              label: `${time.start_time.split(':')[0]} تا ${time.end_time.split(':')[0]}`,
              value: time.id,
              show: false
            })
          })

          this.filtersList.push({
            field: `time`,
            name: this.$t('report.saleDetail.filters.time'),
            type: 'checkbox',
            types: timeList
          })
          this.activeFiltersCount += 1


          if (this.activeFiltersCount === 3) {
            this.getFiltersStatus = false
          }
        })
          .catch(() => {
            this.activeFiltersCount += 1
          })
      },

      handlePrintSaleDetail() {
        showLoading()

        printSaleDetailReport(this.filters, this.sorts).then(response => {
          hideLoading()

          const data = response.data

          let time = getTimeFromServer()
          let fileURL = window.URL.createObjectURL(new Blob([data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `saleDetailReportList-${time}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
          .catch((error) => {
            hideLoading()

            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('report.saleDetail.notifications.print.error'),
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
      },
      handleSelectReport(row) {
        this.selectedReport = row
        this.showUserInvoicesPromptStatus = true
      },
      consoleLog() {
        // console.log(event)
      },
      handleClick(id) {
        document.getElementById(id).click()
      }
    },
    watch: {
      '$store.state.helper.calendarDate': {
        handler(val) {
          this.data = []
          this.page = 1
          this.endedList = false

          let reserveFilterIndex = -1
          this.filters.forEach((filter) => {
            if (filter.startsWith('reserved_date=')) {
              reserveFilterIndex = this.filters.indexOf(filter)
            }
          })
          this.filters.splice(reserveFilterIndex, 1)
          if (typeof val === 'object' && val.length > 1) {
            this.filters.push(`reserved_date=${val.join('_')}`)
          } else {
            this.filters.push(`reserved_date=${val}`)
          }
          if (this.activeFiltersCount > 0) {
            this.getSaleDetailReport()
          }
        }
      }
    }
  }
</script>

<style lang="scss">
</style>
